<template>
  <nav class="navigation-menu-links">
    <ul v-if="isAdmin" class="navigation-menu-links__items">
      <li class="navigation-menu-links__item">
        <div
          @click="handleOpenList('users')"
          :class="['navigation-menu-links__item-container', openItem === 'users' && 'active']"
        >
          <router-link :to="getRoutes('USERS_LIST', true)" tag="a" exact> Users </router-link>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'users'" class="navigation-menu-links__items-second">
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('USERS_LIST', true)" tag="a" exact>
              Users List
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('USER_CREATE')" tag="a" exact> Users Create </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('USER_GROUPS_LIST', true)" tag="a" exact>
              User Groups List
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('USER_GROUP_CREATE')" tag="a" exact>
              User Group Create
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <ul v-if="isAdmin" class="navigation-menu-links__items">
      <li class="navigation-menu-links__item">
        <div
          @click="handleOpenList('subscription')"
          :class="[
            'navigation-menu-links__item-container',
            openItem === 'subscription' && 'active',
          ]"
        >
          <router-link :to="getRoutes('SUBSCRIPTION_LIST', true)" tag="a" exact>
            Subscription
          </router-link>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'subscription'" class="navigation-menu-links__items-second">
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('SUBSCRIPTION_LIST', true)" tag="a" exact>
              Subscription List
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('SUBSCRIPTION_CREATE')" tag="a" exact>
              Subscription Create
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <ul v-if="isAdmin" class="navigation-menu-links__items">
      <li class="navigation-menu-links__item">
        <div
          @click="handleOpenList('faq')"
          :class="['navigation-menu-links__item-container', openItem === 'faq' && 'active']"
        >
          <router-link :to="getRoutes('FAQ_GROUP_LIST', true)" tag="a" exact> FAQ </router-link>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'faq'" class="navigation-menu-links__items-second">
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('FAQ_GROUP_LIST', true)" tag="a" exact>
              FAQ Group
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('FAQ_ITEM_LIST', true)" tag="a" exact>
              FAQ Items
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="navigation-menu-links__items">
      <li v-if="isAdmin || isCopywriter" class="navigation-menu-links__item">
        <div
          @click="handleOpenList('categories')"
          :class="['navigation-menu-links__item-container', openItem === 'categories' && 'active']"
        >
          <router-link :to="getRoutes('CATEGORIES_LIST', true)" tag="a" exact>
            Categories
          </router-link>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'categories'" class="navigation-menu-links__items-second">
          <li v-if="isAdmin" class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('CATEGORIES_GROUPS_LIST', true)" tag="a" exact>
              Categories Group List
            </router-link>
          </li>
          <li v-if="isAdmin" class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('CATEGORIES_GROUP_CREATE')" tag="a" exact>
              Categories Group Create
            </router-link>
          </li>
          <li v-if="isAdmin || isCopywriter" class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('CATEGORIES_LIST', true)" tag="a" exact>
              Categories List
            </router-link>
          </li>
          <li v-if="isAdmin" class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('CATEGORY_CREATE')" tag="a" exact>
              Category Create
            </router-link>
          </li>
        </ul>
      </li>
      <li
        v-if="isAdmin || isCopywriter || isDesigner || isLeadDesigner"
        class="navigation-menu-links__item"
      >
        <div
          @click="handleOpenList('templates')"
          :class="['navigation-menu-links__item-container', openItem === 'templates' && 'active']"
        >
          <router-link :to="getRoutes('TEMPLATES_LIST', true)" tag="a" exact>
            Templates
          </router-link>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'templates'" class="navigation-menu-links__items-second">
          <li
            v-if="isAdmin || isCopywriter || isDesigner"
            class="navigation-menu-links__item-second"
          >
            <router-link :to="getRoutes('TEMPLATES_LIST', true)" tag="a" exact>
              Templates List
            </router-link>
          </li>
          <li v-if="isAdmin" class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATE_QUICK_EDIT', true)" tag="a" exact>
              Template Quick Edit List
            </router-link>
          </li>
          <li v-if="isAdmin" class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_STATISTICS')" tag="a" exact>
              Template Statistics
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin || isLeadDesigner" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('PLANS_LIST', true)" tag="a" exact>
            Content Plans
          </router-link>
        </div>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('HOLIDAYS_LIST', true)" tag="a" exact> Holidays </router-link>
        </div>
      </li>
      <li v-if="isAdmin || isLeadDesigner" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('KEYWORDS_LIST', true)" tag="a" exact> Keywords </router-link>
        </div>
      </li>
      <li v-if="isAdmin && siteId == 7" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('DICTIONARY', true)" tag="a" exact>
            Dictionaries
          </router-link>
        </div>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div
          @click="handleOpenList('references')"
          :class="['navigation-menu-links__item-container', openItem === 'references' && 'active']"
        >
          <router-link :to="getRoutes('TEMPLATES_TYPES_LIST', true)" tag="a" exact>
            References
          </router-link>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'references'" class="navigation-menu-links__items-second">
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_TYPES_LIST', true)" tag="a" exact>
              Templates Types
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_STATUSES_LIST', true)" tag="a" exact>
              Templates Statuses
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_FORMATS_LIST', true)" tag="a" exact>
              Templates Formats
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_TAGS_LIST', true)" tag="a" exact>
              Templates Tags
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_FONTS_LIST', true)" tag="a" exact>
              Templates Fonts
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('LAYOUT_FONTS_LIST', true)" tag="a" exact>
              Layout Fonts
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_COLOR_LIST', true)" tag="a" exact>
              Templates Color
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_STYLE_LIST', true)" tag="a" exact>
              Templates Style
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_SIZES_LIST', true)" tag="a" exact>
              Templates Sizes
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('TEMPLATES_ATTRIBUTES_LIST', true)" tag="a" exact>
              Templates Attributes
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div
          @click="handleOpenList('blog')"
          :class="['navigation-menu-links__item-container', openItem === 'blog' && 'active']"
        >
          <router-link :to="getRoutes('BLOG_GROUPS_LIST', true)" tag="a" exact> Blog </router-link>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'blog'" class="navigation-menu-links__items-second">
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('BLOG_GROUPS_LIST', true)" tag="a" exact>
              Blog Categories Groups List
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('BLOG_CATEGORIES_LIST', true)" tag="a" exact>
              Blog Categories List
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('BLOG_POSTS_LIST', true)" tag="a" exact>
              Blog Posts List
            </router-link>
          </li>
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('BLOG_POST_STATUSES_LIST', true)" tag="a" exact>
              Blog Posts Statuses
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('STATIC_LIST', true)" tag="a" exact>
            Static Page
          </router-link>
        </div>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('INDEXING_LIST', true)" tag="a" exact>
            Indexing Page
          </router-link>
        </div>
      </li>
      <li v-if="isAdmin || isCopywriter" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('SEO_LIST', true)" tag="a" exact> Seo Page </router-link>
        </div>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('LANG_LIST', true)" tag="a" exact> Languages </router-link>
        </div>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div
          @click="handleOpenList('social')"
          :class="['navigation-menu-links__item-container', openItem === 'social' && 'active']"
        >
          <a> Social </a>
          <div class="navigation-menu-links__button">
            <SelectIcon />
          </div>
        </div>
        <ul v-if="openItem === 'social'" class="navigation-menu-links__items-second">
          <li class="navigation-menu-links__item-second">
            <router-link :to="getRoutes('SOCIAL_FB', true)" tag="a" exact> FaceBook </router-link>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('REMOVER_CROP', true)" tag="a" exact> Remover </router-link>
        </div>
      </li>

      <li v-if="isAdmin" class="navigation-menu-links__item">
        <div :class="['navigation-menu-links__item-container']">
          <router-link :to="getRoutes('ONLY_CROP', true)" tag="a" exact> Crop Image </router-link>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import SelectIcon from "~/assets/images/icons/select-icon-small.svg";
import ROUTES from "~/constants/routes";
import { getStoredUserInfo, getSite } from "~/helpers/userAuth";
export default {
  name: "NavigationMenuLinks",
  data() {
    return {
      openItem: "",
      isAdmin: false,
      isManager: false,
      isLeadDesigner: false,
      isCopywriter: false,
      isDesigner: false,
      siteId: null,
    };
  },
  mounted() {
    if (getStoredUserInfo()) {
      const user = getStoredUserInfo();
      for (let i = 0; user.groups.length > i; i++) {
        if (user.groups[i].id == 1 || user.groups[i].id == 2) {
          this.isAdmin = true;
        }

        if (user.groups[i].id == 5) {
          this.isLeadDesigner = true;
        }

        if (user.groups[i].id == 59) {
          this.isManager = true;
        }

        if (user.groups[i].id == 6) {
          this.isCopywriter = true;
        }

        if (user.groups[i].id == 4) {
          this.isDesigner = true;
        }
      }
    }

    if (getSite()) {
      const site = getSite();
      this.siteId = site.id;
    }
  },
  components: { SelectIcon },
  methods: {
    handleOpenList(item) {
      if (this.openItem === item) {
        this.openItem = "";
      } else {
        this.openItem = item;
      }
    },
    getRoutes(name, page = false) {
      if (page) {
        return ROUTES[name].replace(":page", 1);
      } else {
        return ROUTES[name];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-menu-links {
  &__item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid $mainDisabled;
    cursor: pointer;

    a {
      font-size: 14px;
      font-weight: bold;
      color: $mainColor;
    }

    &.active {
      background: $mainSecondColor;
      border-color: transparent;

      a {
        color: $g-color-white;
      }

      .navigation-menu-links__button {
        display: none;
      }
    }
  }

  &__item-second {
    padding: 12px;
    border-bottom: 1px solid $mainDisabled;

    a {
      color: $mainSecondTextColor;
      font-size: 12px;

      &:hover {
        color: $mainColor;
      }
    }
  }
}
</style>
